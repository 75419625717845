#header {
  margin: -5px -20px -14px -20px !important;
}

#categories_strip {
  margin: 0 -20px 10px -20px !important;
}

#sidebar_l {
  margin-left: -8px;
  margin-top:  -3px;
}